
/*separating submit button in view mode from conditions elements*/
.form-submit-button-view {
    margin-top: 20px;
}

.editor-container .ql-editor {
    white-space: pre; /* Keep white spaces and no word wrapping */
    overflow-x: auto;    /* Allows horizontal scrolling */
    tab-size: 4; /* Set tab size for indentation*/
  }
  
